import { useMe } from "@@/authentication/me/me-context";
import { usePageById } from "@@/backoffice/for-providers/pages/queries/page/use-page";
import { AppPage } from "@@/pages/app-page";
import { PageLoad } from "@@/pages/page-load";
import { appPageIdFactory } from "@@/pages/page-navigation/page-navigation-context";
import { useProvidersForArea } from "@@/providers/state/queries/providers-for-area/use-providers-for-area";
import {
    useClientMemoryCache,
    useClientMemoryCacheDispatch,
} from "@@/settings/cache-context";
import { useHomeSettingQuery } from "@@/settings/home/use-home-setting-query";
import { AccountIcon } from "@@/shared/account-icon";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { HeadTitle } from "@@/shared/head-title";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { use100vh } from "@@/shared/use-100-vh";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { PageView } from "@@/store-fronts/pages/page-view";
import { getProviderScope } from "@@/users/scopes";
import { useTheme } from "@emotion/react";
import {
    PageId,
    ProductId,
    Provider,
    getProviderStatus,
    shuffle,
    translation,
    unique,
} from "@towni/common";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { OneSquareSection } from "../shared/one-square-section";
import { LastMileCarSection } from "../smart-resande/sections/last-mile-car-section";
import { BeAPartOfTowniSection } from "./sections/be-a-part-of-towni-section";
import { FeedbackSection } from "./sections/feedback-section";
import { Footer } from "./sections/footer-section";
import { HenrysShrimpSalladSection } from "./sections/henrys-shrimp-sallad";
import { HowDoesItWorkSection } from "./sections/how-does-it-work-section";
import { InspirerasSection } from "./sections/inspireras";
import { LackoPromoSection } from "./sections/lacko-promo-section";
import { OrderFoodSection } from "./sections/order-food-section";
import { ProviderCardSection } from "./sections/providers/provider-card-section";
import { ProviderCardVerticalSection } from "./sections/providers/provider-card-vt-section";
import { ProviderLogoSection } from "./sections/providers/provider-logo-section";
import { ShopLocallyHereSection } from "./sections/shop-locally-here-section";
import { ElectricBikesFourSection } from "./sections/summer-2022/electric-bikes-four-section";
import { ExploreBreathOfTowni } from "./sections/summer-2022/explore-breath-of-towni";
import { HistoricalMeetingsFourSections } from "./sections/summer-2022/historical-meetings-four-section";
import { KronocampingExerciseMindSectionOnTowni } from "./sections/summer-2022/kronocamping-exercise-mind-section-on-towni";
import { KronocampingGetOutAndMoveSectionOnTowni } from "./sections/summer-2022/kronocamping-get-out-and-move-section-on-towni";
import { KronocampingSupSectionOnTowni } from "./sections/summer-2022/kronocamping-sup-section";
import { LackoFamilyAdventure } from "./sections/summer-2022/lacko-family-adventure";
import { LackoNatureExploration } from "./sections/summer-2022/lacko-nature-exploration";
import { NavenLackoExperienceSection } from "./sections/summer-2022/lacko-naven-unique-2-section";
import { NavenExperienceSectionOfTowni } from "./sections/summer-2022/nave-experience-section";
import { SmartResandeElectricalCar } from "./sections/summer-2022/smart-resande-electrical-car";
import { SmartResandeTwo } from "./sections/summer-2022/smart-resande-two";
import { VoucherSection } from "./sections/voucher-section";
import { VoucherSingleSection } from "./sections/voucher-single-section";
import { WhatIsTowniSection } from "./sections/what-is-towni-section";

type Props = {
    areas?: string[];
    categories?: string[];
    tags?: string[];
    title?: string;
};

const OldStoreFront = (props: Props) => {
    const [me] = useMe();
    const vh100 = use100vh();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const theme = useTheme();
    const location = useLocation();
    const clientCacheDispatch = useClientMemoryCacheDispatch();
    const clientCache = useClientMemoryCache();
    const params = new URLSearchParams(location.search);
    // const parsedQuery = queryString.parse(location.search);
    const [areas] = useState(
        unique([...params.getAll("area"), ...(props.areas ? props.areas : [])]),
    );
    const [categories] = useState(
        unique([
            ...params.getAll("cat"),
            ...(props.categories ? props.categories : []),
        ]),
    );
    const [tags] = useState(
        unique([...params.getAll("tag"), ...(props.tags ? props.tags : [])]),
    );
    const [shuffledAt, setShuffledAt] = useState(() => new Date().getTime());
    const filterOn: ("categories" | "areas" | "tags")[] = [];
    if (areas?.length) filterOn.push("areas");
    if (categories?.length) filterOn.push("categories");
    if (tags?.length) filterOn.push("tags");

    const area = "lidköping";
    const [providers, providerQuery] = useProvidersForArea([area]);

    const shuffledProviders = useMemo(() => {
        const sortOrder =
            clientCache.providerSortOrderByArea[area]?.order || [];

        return sortOrder
            .map(providerId => {
                const provider = providers.find(
                    item => item._id === providerId,
                );
                // TODO! REMOVE THIS LATER ON >>
                if (provider?.slug === "lacko") return undefined;
                return provider;
            })
            .filter(Boolean) as Provider[];
    }, [clientCache.providerSortOrderByArea[area]]);

    useEffect(() => {
        // Hidden shuffle
        if (!me) return;
        if (!providers.length || providerQuery.isPending) {
            // console.log("PROVIDERS", providers, providerQuery);
            return;
        }

        const currentOrder =
            clientCache.providerSortOrderByArea?.[area]?.order ?? [];
        const hidden = providers.filter(provider => {
            if (currentOrder.includes(provider._id)) return false;
            if (getProviderStatus(provider) !== "HIDDEN") return false;
            const scopes = getProviderScope({
                providerId: provider._id,
                access: "read",
            });
            const show = me.scopes.some(item => scopes.includes(item));
            return show;
        });
        if (hidden.length) {
            const output = [
                ...(clientCache.providerSortOrderByArea?.[area]?.order ?? []),
                ...hidden.map(item => item._id),
            ];
            clientCacheDispatch({
                type: "SET_PROVIDER_SORT_ORDER_BY_AREA",
                area,
                sortOrder: output,
                tag: shuffledAt,
            });
        }
    }, [shuffledAt, me]);

    useEffect(() => {
        // Main shuffle
        // Shuffle them providers and cache the result
        if (!providers.length || providerQuery.isPending) return;
        const sortOrder =
            clientCache.providerSortOrderByArea[area]?.order || [];

        const active = providers.filter(provider => {
            const status = getProviderStatus(provider);
            return status !== "PAUSED" && status !== "HIDDEN";
        });
        const paused = providers.filter(
            provider => getProviderStatus(provider) === "PAUSED",
        );

        // Already shuffled?
        if (
            [...active, ...paused].every(provider =>
                sortOrder.includes(provider._id),
            )
        )
            return;

        // Nope, then let's go
        const shuffled = [...shuffle(active), ...shuffle(paused)].map(
            item => item._id,
        );

        const at = new Date().getTime();
        clientCacheDispatch({
            type: "SET_PROVIDER_SORT_ORDER_BY_AREA",
            area,
            sortOrder: shuffled,
            tag: at,
        });
        setShuffledAt(at);
    }, [me, providers]);

    if (providerQuery.isError) {
        throw providerQuery.error;
    }

    return (
        <WideScreenWrapper>
            <HeadTitle />
            <AppPage
                pageId={appPageIdFactory("towni-store-front")}
                crossAxis={isDesktop ? "center" : undefined}
                header={
                    <FlexRow
                        fillParentWidth
                        shrink={0}
                        mainAxis="flex-end"
                        crossAxis="center"
                        padding={{ all: 20 }}
                        css={{
                            position: "absolute",
                            zIndex: 50,
                        }}>
                        <AccountIcon />
                    </FlexRow>
                }
                anchors={[]}>
                {/* Header >>>> */}
                <FlexColumn
                    fillParentWidth
                    shrink={0}
                    grow={1}
                    mainAxis="center"
                    crossAxis="center"
                    height={vh100 * 0.65}
                    maxHeight={vh100 * 0.65}
                    maxWidth="100%"
                    background={{
                        imageUrl: `https://we-are-alfred.imgix.net/destinations/towni/welcome-header-fall-2022-bg.jpg?dpr=2&q=60&fm=jpg&w=1200&fit=crop&crop=edges`,
                        fit: "COVER",
                    }}>
                    <FlexRow>
                        <TextBox
                            text="Välkommen till towni.se"
                            color={theme.colors.tertiary}
                            size={isDesktop ? 2 : 1}
                            css={{
                                textShadow: "0px 5px 10px rgba(0,0,0,0.3)",
                            }}
                        />
                    </FlexRow>
                    <VerticalDivider />
                    <FlexRow>
                        <TextBox
                            text="Lokala upplevelser"
                            color={theme.colors.white}
                            size={isDesktop ? 4 : 2}
                            weight="700"
                            css={{
                                letterSpacing: "1px",
                                textShadow: "0px 5px 10px rgba(0,0,0,0.3)",
                            }}
                        />
                    </FlexRow>
                    <FlexRow>
                        <TextBox
                            text="& lokal mat"
                            color={theme.colors.white}
                            size={isDesktop ? 4 : 2}
                            weight="700"
                            css={{
                                textShadow: "0px 5px 10px rgba(0,0,0,0.3)",
                            }}
                        />
                    </FlexRow>
                    <VerticalDivider />
                    <img
                        src={`https://we-are-alfred.imgix.net/destinations/towni/welcome-towni-text-overlay-2022.png?dpr=2&q=60&w=900&fit=crop&crop=edges`}
                        css={{
                            maxWidth: "13%",
                            display: "block",
                            cursor: "pointer",
                        }}
                    />
                </FlexColumn>
                {/* <<<< Header */}

                {/* Content >>>> */}
                <FlexColumn fillParentWidth maxWidth={800}>
                    {/* <VerticalDivider S />
                    <VerticalDivider ML />
                    <OneSquareSectionShared
                        productId={"product__-Au9W2Cla7UIpLEL8l_G" as ProductId}
                        sectionTitle={"Som ett vrak!"}
                        sectionSubTitle={
                            "Vänern är fullt med häftiga vrak från svunna tider. Här guidas ni att upptäcka på en fin kanottur i skärgården"
                        }
                        actionText={"Upptäck & Boka"}
                    />
                    <VerticalDivider XL />*/}
                    <VerticalDivider XL />

                    <ShopLocallyHereSection />
                    <VerticalDivider XL />
                    <ProviderCardSection
                        storeFrontSectionId="hitta-din-favoritmat"
                        title={translation({
                            sv: "Hitta din favoritmat!",
                            en: "Find your favorite food!",
                        })}
                        subtitle={translation({
                            sv: "Varför inte ta en pickup och äta ute vid vattnet",
                            en: "Home delivery or pickup",
                        })}
                        categoryFilter={["edible"]}
                        providers={shuffledProviders}
                        backgroundColor={theme.colors.primary.light}
                    />
                    <VerticalDivider XS />
                    <ProviderLogoSection
                        sectionId={"provider-logo-section-1"}
                        providers={shuffledProviders}
                    />
                    <VerticalDivider XS />
                    <ExploreBreathOfTowni />
                    <VerticalDivider XL />
                    <VoucherSection />
                    <VerticalDivider XL />
                    <LackoPromoSection />
                    <VerticalDivider XL />
                    <OneSquareSection
                        // Mellby bordsbokning
                        productId={"product__NBu1_tL2AkYywtiiDBKZ" as ProductId}
                        actionText={translation({
                            sv: "Boka bord",
                            en: "Table booking",
                        })}
                    />
                    <VerticalDivider XS />
                    <OrderFoodSection />
                    <VerticalDivider M />
                    <HenrysShrimpSalladSection />
                    <ProviderCardSection
                        storeFrontSectionId="hitta-din-favoritbutik"
                        title={translation({
                            sv: "Hitta din favoritbutik!",
                            en: "Find your favorite shop!",
                        })}
                        subtitle={translation({
                            sv: "Hemleverans eller egen pickup",
                            en: "Home delivery or pickup",
                        })}
                        categoryFilter={["retail"]}
                        providers={shuffledProviders}
                    />
                    <VerticalDivider S />
                    <InspirerasSection />
                    <VerticalDivider L />
                    {/* <MeetUpOutsideSection />
                    <VerticalDivider S /> */}
                    <ProviderLogoSection
                        sectionId={"provider-logo-section-2"}
                        providers={shuffledProviders}
                    />
                    <VerticalDivider S />

                    {/* >> Nästa sommar */}
                    <VerticalDivider XXL />
                    <TextBox
                        text={translation({
                            sv: "På återseende nästa sommar...",
                            en: "See you next summer...",
                        })}
                        size={2}
                        weight="900"
                        padding={{ left: 20 }}
                    />
                    <VerticalDivider L />
                    <FlexColumn
                        fillParentWidth
                        css={{
                            opacity: 0.8,
                            filter: "grayscale(30%)",
                            background: theme.colors.primary.light.asString,
                        }}>
                        <SmartResandeTwo />
                        <VerticalDivider XL />
                        <KronocampingSupSectionOnTowni />
                        <VerticalDivider XL />
                        <KronocampingExerciseMindSectionOnTowni />
                        <VerticalDivider XL />
                        <LackoNatureExploration />
                        <VerticalDivider XL />
                        <KronocampingGetOutAndMoveSectionOnTowni />
                        <VerticalDivider XL />
                        <LackoFamilyAdventure />
                        <VerticalDivider XL />
                        <ElectricBikesFourSection />
                        <VerticalDivider XL />
                        <OneSquareSection
                            sectionTitle={"Väners egna Vikingaskepp"}
                            sectionSubTitle={
                                "Gå ombord och boka en kulturhistorisk resa du sent kommer glömma."
                            }
                            // Sigrid storråda
                            productId={
                                "product__1-nCti2n1mNh1nv7LXVg" as ProductId
                            }
                            actionText={translation({
                                sv: "För barnen",
                                en: "For the children",
                            })}
                        />
                        <VerticalDivider XL />
                        <NavenLackoExperienceSection />
                        <VerticalDivider XL />
                        {/* <OneSquareSection
                            productId={
                                "product__RxdPdJ26sautittVnHOq" as ProductId
                            }
                            sectionTitle={
                                "Hyr bryggabastu med badtunna grillkåta och utsikt!"
                            }
                            sectionSubTitle={
                                "Gemenskap, mat och avkoppling. Finns det en bättre lugn stund än med solnedgång över kobbar och skär?"
                            }
                            actionText={"Läs & Boka"}
                        />
                        <VerticalDivider XL /> */}
                        {/* <VanerMuseumFourSection /> */}
                        {/* <VerticalDivider XL /> */}
                        <VoucherSingleSection />
                        <VerticalDivider XL />
                        <HistoricalMeetingsFourSections />
                        <VerticalDivider XL />
                        <LastMileCarSection
                            productId={
                                "product__IG8ZXjoF9TW197TdJKI8" as ProductId
                            }
                            actionText={translation({
                                sv: "Se & Boka",
                                en: "Book now!",
                            })}
                        />
                        <VerticalDivider XL />
                        <NavenExperienceSectionOfTowni />
                        <VerticalDivider XL />
                        <SmartResandeElectricalCar />
                    </FlexColumn>
                    {/* << Nästa sommar */}
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                    <TextBox
                        text={translation({
                            sv: "Vi finns på towni",
                            en: "We are on towni",
                        })}
                        size={2}
                        weight="900"
                        padding={{ left: 20 }}
                    />
                    <VerticalDivider L />
                    <ProviderCardVerticalSection
                        isLoading={providerQuery.isPending}
                        providers={shuffledProviders}
                    />
                    <HowDoesItWorkSection />
                    <VerticalDivider L />
                    <WhatIsTowniSection />
                    <VerticalDivider L />
                    <BeAPartOfTowniSection />
                    <VerticalDivider L />
                    <FeedbackSection />
                    <VerticalDivider S />
                    <VerticalDivider S />
                </FlexColumn>
                {/* <<<< Content */}
                <Footer />
            </AppPage>
        </WideScreenWrapper>
    );
};

const PageStoreFront = (props: { pageId: PageId }) => {
    const [, pageQuery] = usePageById(props.pageId || undefined);
    const isPending = pageQuery.isPending;
    const page = pageQuery.data;
    return <PageView page={page} isPending={isPending} />;
};

const StoreFront = (props: Props) => {
    const homeSettingQuery = useHomeSettingQuery();
    const pageId = homeSettingQuery.data?.pageId || null;
    const isPending = homeSettingQuery.isPending;
    if (isPending) return <PageLoad />;
    if (pageId) return <PageStoreFront pageId={pageId} />;
    return <OldStoreFront {...props} />;
};

export { StoreFront };
